.contactUs {
  padding-block-start: 10rem;
  &::after {
    content: "";
    display: inline-block;
    position: absolute;
    inset-inline: 0;
    inset-block-start: 0;
    height: 30%;
    background-image: linear-gradient(to bottom, #333333 50%, #33333300);
    @media screen and (max-width: 1200px) {
      height: 50%;
    }
    @media screen and (max-width: 769px) {
      display: none;
    }
  }
  &Map {
    width: 70%;
    border: none;
    @media screen and (max-width: 769px) {
      width: 100%;
      margin-block-start: -16rem;
    }
    &Content {
      color: #fff;
      background-color: transparent;
      position: absolute;
      background-image: linear-gradient(to right, #333333 80%, #33333300);
      width: 50%;
      inset-block: 0;
      z-index: 1;
      inset-inline-start: 0;
      padding-block-start: 5rem;
      padding-inline-start: 10vw;
      display: flex;
      flex-direction: column;
      gap: 7rem;
      &:lang(ar) {
        background-image: linear-gradient(to left, #333333 80%, #33333300);
      }
      @media screen and (max-width: 1200px) {
        padding-block-start: 10rem;
        padding-inline-start: 5vw;
      }
      @media screen and (max-width: 769px) {
        background-image: linear-gradient(to bottom, #333333 80%, #33333300);
        position: relative;
        width: 100%;
        padding-block: 10rem 15rem;
        padding-inline: 5%;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
      }
      &Container {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        position: relative;
        background-color: transparent;
      }
    }
  }
  &Header {
    font-size: 3.25rem;
    font-weight: 900;
    text-transform: capitalize;
    @media screen and (max-width: 769px) {
      width: 100%;
    }
  }
  &SeeUs,
  &GetInTouch,
  &Follow {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem 1rem;
    &Header {
      font-size: 2.25rem;
      font-weight: 700;
      text-transform: capitalize;
    }
    &Email,
    &Header {
      width: 100%;
    }
    &Body {
      width: 50%;
      @media screen and (max-width: 769px) {
        width: 100%;
      }
    }
    &Body,
    &Email,
    &Phone,
    &Land {
      text-transform: capitalize;
      font-size: 1.25rem;
    }
    &Phone,
    &Land {
      width: fit-content;
    }
  }
  &SeeUs,
  &GetInTouch {
    @media screen and (max-width: 769px) {
      width: calc(50% - 3.5rem);
    }
  }
  &SeeUs,
  &GetInTouch,
  &Follow {
    @media screen and (max-width: 640px) {
      width: 100%;
    }
  }
}

.socialIcon {
  border: 1px solid;
  border-radius: 100%;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
