.loading {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ccc;
}

.logo {
  &Container {
    position: relative;
  }
  &FrontLayer {
    position: absolute;
    inset-inline-start: -100%;
    inset-block-start: -350%;
    width: 150%;
    height: 400%;
    transform: rotate(45deg);
    border-right: 1px solid #000;
    background-color: #ccc;
    &:lang(ar) {
      inset-inline-start: -160%;
      inset-block-start: 175%;
      transform: rotate(-135deg);
    }
  }
}
