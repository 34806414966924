.numberLabel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  font-size: 2.2rem;
  font-weight: 500;
  width: fit-content;
  text-transform: capitalize;
  margin-inline: auto;
  text-align: center;
  &.rightBorder {
    border-inline-end: 1px solid #00000033;
  }
}
.number {
  font-family: var(--prata);
  color: var(--main-red);
  @media screen and (max-width: 1200px) {
    scale: 0.858;
  }
}
