.video {
  width: 100%;
  height: fit-content;
  &Container {
    position: relative;
  }
  &Controls {
    position: absolute;
    inset: 0;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    color: #fff;
    font-size: 1.7rem;
    font-weight: 500;
    padding: 5%;
    cursor: pointer;
    &Container {
      display: flex;
      align-items: center;
      gap: 2rem;
    }
  }
  &PlayBtn {
    all: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1;
    width: 50px;
    border: 2px solid #fff;
    border-radius: 100%;
    padding: 1rem;
    cursor: pointer;
    & img {
      transform: translateX(0.25rem);
    }
  }
}
