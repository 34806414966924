.moreAboutUs {
  display: flex;
  align-items: center;
  gap: 2rem;
  overflow: visible;
  svg:lang(ar) {
    scale: -1;
  }
  &Text {
    font-size: 1.7rem;
    font-weight: 500;
    text-transform: capitalize;
  }
}
