.slick-dots {
  display: flex !important;
  margin-inline: auto;
  position: static;
  width: fit-content;
  height: 5px;
  background-color: #00000019;
  border-radius: 5px;
  margin-block-start: 2rem;
}
.slick-dots li {
  margin: 0;
  height: 5px;
}

li.slick-active {
  border-radius: 10px;
  width: 30px;
  background-color: #000 !important;
  flex-grow: 1;
}

li button {
  display: none !important;
}