.whyUs {
  padding-inline: 10%;
  background-color: var(--main-red);
  padding-block: 9%;
  color: #ffffff;
  @media screen and (max-width: 1200px) {
    padding-inline: 5%;
  }
  &Text {
    font-size: 1.8rem;
    text-transform: capitalize;
  }
  &Content {
    display: flex;
    margin-top: 3rem;
    gap: 4rem;
    @media only screen and (max-width: 768px) {
      flex-direction: column;
    }
  }
  &Header {
    width: 50%;
    font-size: 3.25rem;
    font-weight: 700;
    text-transform: capitalize;
    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }
  &Body {
    width: 50%;
    font-size: 1.7rem;
    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }
  &Offering {
    margin-block-start: 15rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 10rem;
    @media only screen and (max-width: 768px) {
      margin-block-start: 5rem;
      row-gap: 2.5rem;
    }
  }
}
