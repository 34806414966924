.hamburgerMenu {
  display: none;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  height: 60%;
  aspect-ratio: 1;
  cursor: pointer;
  @media screen and (max-width: 768px) {
    display: flex;
  }
}

.line {
  position: relative;
  background-color: #333;
  border-radius: 5px;
  height: 5px;
  width: 30px;
  &::before,
  &::after {
    content: "";
    position: absolute;
    display: inline-block;
    width: 100%;
    height: 5px;
    border-radius: 5px;
    background-color: #333;
    transition: all 200ms ease-in-out;
  }
  &::before {
    top: -10px;
  }
  &::after {
    top: 10px;
  }
}

.lineHide {
  background-color: #fff;
  &::before {
    transform: translate(0, 10px) rotate(45deg);
  }
  &::after {
    transform: translate(0, -10px) rotate(-45deg);
  }
}

.responsiveMenu {
  display: none;
  position: fixed;
  inset: 0;
  background-color: #fff;
  width: 100%;
  z-index: -1;
  inset-inline-start: 100%;
  animation: slideOut 350ms linear forwards;
  padding-block: 10rem;
  @media screen and (max-width: 768px) {
    display: block;
  }
  &List {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 3.5rem;
    padding-inline: 5%;
    padding-block: 2rem;
    list-style: none;
    text-transform: uppercase;
    font-size: 2.5rem;
    font-weight: 500;
    text-transform: uppercase;
    &Item {
      font-size: 3.5rem;
      font-weight: 900;
      letter-spacing: 1px;
      &:hover{
        .submenu{
          display: block;
        }
      }
      .submenu{
        display: none;
        list-style: none;
        text-indent: 2rem;;
        li{
          font-size: 3rem;;
          text-transform: capitalize;
          margin-block:1.3rem;
          font-weight: 800;
        }
      }
      &:last-of-type {
        margin-block-end: 5rem;
      }
    }
  }
}

.responsiveMenuAnimation {
  animation: slideIn 350ms linear forwards;
}

.socialMedia {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin-block-start: 3rem;
  &Link {
    display: flex;
    width: 3rem;
    aspect-ratio: 1;
    justify-content: center;
    align-items: baseline;
    &Image {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

@keyframes slideIn {
  0% {
    inset-inline-start: 100%;
  }
  100% {
    inset-inline-start: 0;
  }
}

@keyframes slideOut {
  0% {
    inset-inline-start: 0;
  }
  100% {
    inset-inline-start: 100%;
  }
}
