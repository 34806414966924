.ourApproach {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  padding-block: 5%;
  padding-inline: 10%;
  background-color: #8082861A;
  background-image: url(../../assets/images/approuch.png);
  background-repeat: no-repeat;
  background-position: right;
  @media screen and (max-width: 768px) {
    padding-inline: 5%;
  }
  &Header {
    font-size: 3.25rem;
    font-weight: 700;
    text-transform: capitalize;
  }
  &Container {
    display: flex;
    flex-wrap: wrap;
    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
  }
  &No {
    color: var(--main-red);
    font-weight: 700;
    font-size: 2.5rem;
  }
  &Title {
    font-weight: 700;
    font-size: 2.5rem;
  }
  &Body {
    font-size: 1.5rem;
    line-height: 2.4rem;
    font-weight: 300;
  }
  &Content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    &Container {
      width: 50%;
      display: flex;
      gap: 2rem;
      padding-block: 1rem 5rem;
      @media screen and (max-width: 768px) {
        width: 100%;
      }
      &:nth-of-type(1) {
        border-inline-end: 1px solid #00000040;
        border-block-end: 1px solid #00000040;
      }
      &:nth-of-type(2) {
        border-block-end: 1px solid #00000040;
        @media screen and (max-width: 768px) {
          border-inline-start: 1px solid #00000040;
        }
      }
      &:nth-of-type(3) {
        border-inline-end: 1px solid #00000040;
      }
      &:nth-of-type(4) {
        @media screen and (max-width: 768px) {
          border-block-start: 1px solid #00000040;
          border-inline-start: 1px solid #00000040;
        }
      }
      &:nth-of-type(2n+1) {
        padding-inline-end: 3rem;
      }
      &:nth-of-type(2n) {
        padding-inline-start: 3rem;
      }
      &:nth-of-type(1),
      &:nth-of-type(2) {
        @media screen and (max-width: 768px) {
          padding-block-start: 3rem;
        }
      }
      &:nth-of-type(3),
      &:nth-of-type(4) {
        padding-block-start: 3rem;
      }
    }
  }
}