.carousel {
  position: relative;
  height: 100vh;
  background-color: #000;
  &Controls {
    display: flex;
    align-items: center;
    gap: 3rem;
    position: absolute;
    inset-block-end: 28.5%;
    inset-inline-start: 10%;
    z-index: 1;
    color: #fff;
    @media screen and (max-width: 1200px) {
      inset-inline-start: 5%;
    }
    &Number {
      font-size: 1.6rem;
      text-shadow: 1px 1px 1rem #00000011, -1px -1px 1rem #00000011;
    }

    &Btn {
      border: 0;
      background-color: transparent;
      cursor: pointer;
    }
  }

  &Slide {
    height: 100vh;
    max-height: 100vh;
    position: relative;

    &Image {
      width: 100%;
      height: 100%;
      object-fit: fill;
      opacity: 0.5;

      &Overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        inset: 0;
        background-color: #00000033;
        z-index: 1;
      }
    }

    &Content {
      position: absolute;
      inset-block-start: 40%;
      inset-inline-start: 10%;
      transform: translateY(-50%);
      width: 40%;
      color: #fff;
      display: flex;
      flex-direction: column;
      gap: 3rem;
      &:lang(ar) {
        direction: rtl;
      }
      @media screen and (max-width: 1200px) {
        inset-inline-start: 5%;
      }
      @media screen and (max-width: 900px) {
        width: 75%;
      }
      @media screen and (max-width: 768px) {
        width: 90%;
      }
    }

    &Title {
      width: 100%;
      text-transform: uppercase;
      font-size: 5rem;
      line-height: 1.5;
      text-shadow: 1px 1px 1rem #00000011, -1px -1px 1rem #00000011;
      @media screen and (max-width: 1200px) {
        font-size: 3.25rem;
      }
    }

    &Description {
      font-size: 1.7rem;
      line-height: 2;
      text-shadow: 1px 1px 1rem #00000011, -1px -1px 1rem #00000011;
      @media screen and (max-width: 1200px) {
        font-size: 1.55rem;
      }
    }
  }
}

.prevBtn:lang(ar) {
  transform: scaleX(-1);
}

.nextBtn:lang(ar) {
  transform: scaleX(-1);
}
