.footer {
  background-color: rgba(#a7a9ac, 0.32);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #000;
  &Line {
    width: 80%;
    opacity: 0.3;
    @media screen and (max-width: 1200px) {
      width: 90%;
    }
  }
  &Links {
    padding: 5% 10%;
    width: 100%;
    display: flex;
    gap: 6rem;
    @media screen and (max-width: 1200px) {
      padding: 5%;
    }
    @media screen and (max-width: 951px) {
      flex-wrap: wrap;
      justify-content: space-between;
    }
    @media only screen and (max-width: 768px) {
      flex-direction: column;
    }
  }
  &Logo {
    display: flex;
    flex-direction: column;
    gap: 5rem;
    width: 30%;
    @media screen and (max-width: 951px) {
      width: 100%;
    }
  }
}

.links {
  &Container {
    display: flex;
    gap: 5rem;
    margin-inline-start: auto;
    @media screen and (max-width: 951px) {
      margin-inline-start: 0;
      justify-content: space-between;
      width: 58%;
    }
    @media only screen and (max-width: 768px) {
      flex-direction: column;
    }
  }
  &Header {
    font-size: 1.7rem;
    font-weight: 600;
    text-transform: capitalize;
  }
  &Flex {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
  }
}

.link {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-size: 1.6rem;
  text-transform: capitalize;
}

.address {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 2.7rem;
}

.getInTouch {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  & .linksHeader {
    margin-block-end: 1.5rem;
  }
  .contactPhone{
    display: flex;
    align-items: center;
    span{
      font-size: 1.6rem;
    }
    .phone{
      &:first-of-type{
        margin-inline-end:6px
      }
      &:last-of-type{
        margin-inline-start:6px

      }
    }
  }
}

.phone,
.email {
  font-size: 1.6rem;
}

.socialMedia {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.contactUs {
  padding: 5% 10%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3.8rem;
  @media screen and (max-width: 1200px) {
    padding: 5%;
  }
  &Text {
    font-size: 1.8rem;
    font-weight: 500;
    text-transform: capitalize;
    color: var(--main-red);
  }
  &Header {
    font-size: 3.25rem;
    font-weight: 700;
    text-transform: capitalize;
    margin-block-end: 3rem;
  }
}

