.vacancyCard {
  padding: 3.3rem 3.6rem;
  background-color: #f5f5f5;
  width: 100%;
  cursor: pointer;

  .department {
    color: #912934;
    margin-bottom: 1.7rem;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 2.4rem;
  }

  h3 {
    font-size: 1.7rem;
    font-weight: 700;
    color: #000000;
  }

  .expWrapper {
    border-top: 1px solid #fff;
    margin-block-start: 3.2rem;
    padding-block-start: 2.2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      font-size: 1.8rem;
      font-weight: 400;
      text-transform: capitalize;
      color: #000;
    }

    svg:lang(ar) {
      scale: -1;
    }
  }
}
