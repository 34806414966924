.navbar {
  position: absolute;
  padding-inline: 10%;
  z-index: 10;
  height: 10rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  @media screen and (max-width: 1200px) {
    padding-inline: 5%;
  }
}
.dark {
  background-color: #333;
}
