.navbarMenu {
  background-color: #fff;
  position: absolute;
  inset-block-start: 10rem;
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-inline: 10%;
  z-index: 5;
  display: flex;
  align-items: center;
  gap: 7rem;
  @media screen and (max-width: 1200px) {
    padding-inline: 5%;
  }
  &Content {
    display: flex;
    flex-direction: column;
    gap: 4rem;
    &Title {
      font-size: 3rem;
      font-weight: 600;
      text-transform: capitalize;
    }
    &Text {
      font-size: 1.35rem;
    }
  }
}

.cardContainer {
  width: 80%;
  display: flex;
  gap: 2rem;
}

.dark {
  background-color: #333;
  color: #fff;
}
