.ourStory {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding-block: 5%;
  padding-inline: 12.5%;
  @media screen and (max-width: 768px) {
    padding: 7.5%;
  }
  &Text {
    font-size: 1.8rem;
    color: var(--main-red);
    text-transform: capitalize;
  }
  &Header {
    font-size: 3.25rem;
    font-weight: 900;
    text-transform: capitalize;
  }
  &Body {
    font-size: 1.7rem;
    line-height: 3.4rem;
    text-transform: lowercase;
    margin-block-start: 0.5rem;
    @media screen and (max-width: 768px) {
      font-size: 1.8rem;
    }
  }
  &Image {
    position: absolute;
    height: 110%;
    inset-block-start: -1rem;
    inset-inline-start: 0;
    z-index: -1;
  }
}
