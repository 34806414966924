.card {
  background-color: #ffffff;
  padding: 6rem;
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  position: relative;
  cursor: pointer;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
  &:hover {
    .cardLogo {
      filter: grayscale(0);
    }
    .cardImage {
      opacity: 1;
    }
  }
  &Logo {
    width: 100%;
    height: 40px;
    object-fit: contain;
    object-position: left;
    filter: grayscale(1);
    transition: all 350ms linear;
    margin-block-end: 1.5rem;
    &:lang(ar) {
      object-position: right;
    }
  }
  &Header {
    font-size: 2.2rem;
    font-weight: 600;
    text-transform: capitalize;
  }
  &Body {
    font-size: 1.7rem;
    margin-block-end: 4rem;
  }
  &Image {
    position: absolute;
    inset: 0 0 0 50%;
    width: 50%;
    height: 100%;
    object-fit: contain;
    object-position: bottom;
    transition: all 350ms linear;
    opacity: 0.5;
    &:lang(ar) {
      inset: 0 50% 0 0;
      transform: scaleX(-1);
    }
  }
}
