.aboutUs {
  padding-inline: 10%;
  padding-block: 7.5% 5%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6rem;
  @media screen and (max-width: 1200px) {
    padding-inline: 5%;
  }
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
  &Content {
    display: flex;
    flex-direction: column;
    gap: 4rem;
    width: 75%;
    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }
  &Text {
    font-size: 1.8rem;
    font-weight: 500;
    text-transform: capitalize;
    color: var(--main-red);
  }
  &Body {
    font-size: 1.7rem;
    line-height: 2.9rem;
    margin-block-end: 3rem;
  }
  &Image {
    @media only screen and (max-width: 768px) {
      width: 70%;
      height: 70vw;
      object-fit: contain;
    }
  }
  &Background {
    position: absolute;
    inset-inline-end: 0;
    @media screen and (max-width: 768px) {
      display: none;
    }
  }
}
