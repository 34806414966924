.badge {
  padding: 1.3rem 3.2rem;
  display: inline-block;
  text-align: center;
  font-size: 1.7rem;
  font-weight: 500;
  background-color: #f5ecec;
  border-radius: 28px;
  text-transform: capitalize;
  align-items: center;
  display: flex;
  width: fit-content;
  gap: 1rem;
}
