.partners {
  padding-block: 5%;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  &Text {
    font-size: 1.8rem;
    font-weight: 500;
    text-transform: capitalize;
    color: var(--main-red);
    padding-inline: 10%;
    margin-block-end: 2rem;
    @media screen and (max-width: 1200px) {
      padding-inline: 5%;
    }
  }
  &Content {
    padding-inline: 10%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-block-end: 4rem;
    @media screen and (max-width: 1200px) {
      padding-inline: 5%;
    }
    @media only screen and (max-width: 768px) {
      flex-direction: column;
      gap: 2rem;
    }
  }
  &Header {
    width: 50%;
    font-size: 3.25rem;
    font-weight: 700;
    text-transform: capitalize;
    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }
  &Body {
    width: 40%;
    font-size: 1.7rem;
    color: #a7a9ac;
    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }
}
