.card {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 31%;
  @media only screen and (max-width: 850px) {
    width: 50%;
  }
  @media only screen and (max-width: 650px) {
    width: 100%;
  }
  &Icon {
    width: 7rem;
    height: 7rem;
    background-color: #ffffff30;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &Image {
    width: 50%;
    height: auto;
    object-fit: contain;
  }
  &Header {
    font-size: 2.4rem;
    font-weight: 600;
  }
  &Body {
    font-size: 1.8rem;
    color: #ffffff6f;
  }
}
