.explore {
  width: 40%;
  height: 17.5%;
  background-color: #fff;
  padding-block: 5vw;
  padding-inline: 10vw 2.5vw;
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
  @media screen and (max-width: 1200px) {
    padding-inline: 5vw 2.5vw;
  }
  @media screen and (max-width: 900px) {
    width: 55%;
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
  &Text {
    font-size: 2.25rem;
    font-weight: 500;
    @media screen and (max-width: 768px) {
      font-size: 3rem;
    }
  }
}
