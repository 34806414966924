.inputsWrapper {
  display: flex;
  gap: 5rem 2.5rem;
  flex-wrap: wrap;

  & > div {
    flex-basis: calc(50% - 1.25rem);

    @media (max-width: 600px) {
      flex-basis: 100%;
    }

    &.fullWidth {
      flex-basis: 100%;
    }
  }
}

.submitBtn {
  all: unset;
  margin-block-start: 5rem;
  cursor: pointer;
  &:lang(ar) {
    scale: -1;
  }
  &:hover {
    opacity: 0.8;
  }
  &Icon {
    width: 75px;
    height: 75px;
    @media screen and (max-width: 768px) {
      width: 60px;
      height: 60px;
    }
  }
}

.message {
  font-size: 1.5rem;
  margin-top: 2rem;
  text-transform: capitalize;
}

.success {
  color: green;
}

.failure {
  color: red;
}
