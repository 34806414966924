.navbarList {
  list-style: none;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: 500;
  width: 45%;
  height: 100%;
  padding-inline-start: 2rem;
  @media screen and (max-width: 900px) {
    width: 55%;
  }
  @media only screen and (max-width: 768px) {
    display: none;
  }
  &Item,
  &Button {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    cursor: pointer;
  }
  &Item {
    transition: color 350ms ease-in-out;
    height: 100%;
    &:hover {
      color: #912934;
    }
  }
}

.dark {
  color: #fff;
}

.active {
  font-size: 700;
  color: #912934;
}
