.companies {
  padding-inline: 10%;
  padding-block: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3.5rem;
  background-color: rgba(#808286,0.1);
  @media screen and (max-width: 1200px) {
    padding-inline: 5%;
  }
  &Text {
    font-size: 1.8rem;
    font-weight: 500;
    color: var(--main-red);
    text-transform: capitalize;
  }
  &Header {
    font-size: 3.25rem;
    font-weight: 700;
    text-transform: capitalize;
  }
  &CardContainer {
    margin-block-start: 5rem;
    display: flex;
    gap: 3rem;
    @media only screen and (max-width: 768px) {
      flex-direction: column;
    }
  }
}