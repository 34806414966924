.careers {
  padding-block-start: 10rem;

  .careersImage {
    background-color: #000;
    & img {
      height: fit-content;
      max-width: 100%;
      object-fit: contain;
      opacity: 0.5;
    }
  }

  .viewVacancies {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-block: 5%;
    padding-inline: 10%;
    @media screen and (max-width: 1200px) {
      padding-inline: 5%;
    }

    .higLogo {
      margin-bottom: 5.6rem;
    }

    p {
      font-size: 1.7rem;
      text-align: center;
      line-height: 4.5rem;
      margin-bottom: 25px;
    }

    a {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 2.1rem;
      cursor: pointer;
      text-decoration: none;
      color: #000;
      font-size: 1.7rem;

      span {
        transform: rotate(90deg);
      }
    }
  }
}
