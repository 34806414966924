.jobDetails {
  min-height: 100vh;
  padding-block-start: 10rem;

  .buttonWrapper {
    padding-block: 5%;
    padding-inline: 10%;

    @media screen and (max-width: 1200px) {
      padding-inline: 5%;
    }

    a {
      all: unset;
      display: flex;
      align-items: center;
      gap: 1.8rem;
      font-size: 1.7rem;
      font-weight: 500;
      line-height: 2.7rem;
      /* 135% */
      text-transform: capitalize;
      cursor: pointer;

      svg {
        transform: rotate(180deg);
        &:lang(ar) {
          scale: -1;
        }
      }

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .jobDetailsImage {
    background-color: #000;

    img {
      object-fit: cover;
      height: fit-content;
      max-height: 600px;
      opacity: 0.7;
    }
  }

  .jobContent {
    width: 80%;
    margin-inline: auto;
    margin-block-start: -20%;
    position: relative;
    background-color: #fff;
    padding: 10rem;
    @media screen and (max-width: 1200px) {
      width: 90%;
    }

    .jobHeader {
      text-align: center;
      margin-bottom: 5rem;

      p {
        font-size: 2rem;
        font-weight: 500;
        text-transform: capitalize;
      }

      h1 {
        margin-bottom: 4rem;
        font-size: 3.25rem;
        font-weight: 700;
        line-height: 9rem;
      }

      .badges {
        margin-bottom: 5rem;
        display: flex;
        justify-content: center;
        gap: 2rem;
      }

      .applyNowBtn {
        all: unset;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2rem;
        font-size: 1.7rem;
        font-weight: 500;
        margin-inline: auto;
        text-transform: capitalize;
        cursor: pointer;

        svg {
          transform: rotate(90deg);
        }
      }
    }

    .jobBody {
      font-size: 1.5rem;
      p,
      div {
        margin-bottom: 7rem;
      }

      ul {
        margin-bottom: 4rem;
        padding-inline-start: 4rem;
      }

      li {
        margin-bottom: 1.4rem;
      }

      p,
      li {
        font-size: 1.7rem;
        font-weight: 400;
        line-height: 3rem;
      }

      h5 {
        font-size: 2.4rem;
        font-weight: 600;
        color: #000;
        margin-bottom: 3rem;
        text-transform: capitalize;
      }
    }
  }

  @media (max-width: 768px) {
    .jobContent {
      margin-block-start: -50%;
      padding: 5rem;
    }
  }

  @media (max-width: 500px) {
    .jobDetailsImage {
      display: none;
    }

    .jobContent {
      margin-block-start: 0;
      padding: 5rem;
    }
  }
}
