.pageInformations {
  padding-block: 3% 5%;
  padding-inline: 10%;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  @media screen and (max-width: 1200px) {
    padding-inline: 5%;
  }
  &Content {
    display: flex;
    gap: 4rem;
    align-items: center;
    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
    .header {
      width: 60%;
      font-size: 3.25rem;
      font-weight: 700;

      @media screen and (max-width: 1200px) {
        width: 50%;
      }

      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }

    .body {
      width: 40%;
      font-size: 1.5rem;
      font-size: 400;

      @media screen and (max-width: 1200px) {
        width: 50%;
      }

      @media screen and (max-width: 768px) {
        width: 100%;
        font-size: 1.8rem;
      }
    }
  }

  .subtitle {
    color: #912934;
    display: block;
    font-size: 1.8rem;
    font-weight: 500;
    text-transform: capitalize;
  }
}
