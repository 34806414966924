.vacancies {
  padding-block: 5%;
  padding-inline: 10%;
  @media screen and (max-width: 1200px) {
    padding-inline: 5%;
  }
  .vacanciesGrid {
    display: flex;
    gap: 3rem 1.5rem;
    flex-wrap: wrap;
    @media screen and (max-width: 1000px) {
      gap: 2.6rem;
    }
    & > div {
      flex-basis: calc(33.3% - 1rem);
    }

    @media (max-width: 1000px) {
      & > div {
        flex-basis: calc(50% - 2.6rem);
      }
    }

    @media (max-width: 650px) {
      & > div {
        flex-basis: calc(100%);
      }
    }
  }

  button {
    display: block;
    margin: 0 auto;
    text-align: center;
    border: none;
    background-color: transparent;
    margin-top: 9rem;
    cursor: pointer;
    font-size: 1.7rem;
    font-weight: 500;
    line-height: 3rem;
    text-transform: capitalize;

    span {
      display: block;
      margin-top: 1rem;
    }
  }
}

.upArrow {
  transform: rotate(180deg);
}
