.main {
  min-height: 100vh;
}

/* Enable hover only on non-touch devices */
@media (hover: hover) and (pointer: fine) {
}

@media (prefers-reduced-motion) {
}

/* Mobile */
@media (max-width: 700px) {
}

/* Tablet and Smaller Desktop */
@media (min-width: 701px) and (max-width: 1120px) {
}

@media (prefers-color-scheme: dark) {
}
