.navbar {
  &Backlayer {
    width: 45%;
    inset-block-start: 0;
    inset-inline-end: 0;
    position: absolute;
    height: 10rem;
    background-color: #fff;
    z-index: 2;
    @media screen and (max-width: 900px) {
      width: 55%;
    }
    @media only screen and (max-width: 768px) {
      display: none;
    }
  }
  &DarkBacklayer {
    width: 45%;
    inset-block-start: 0;
    inset-inline-end: 0;
    position: absolute;
    height: 10rem;
    background-color: #fff;
    z-index: 2;
    @media screen and (max-width: 900px) {
      width: 55%;
    }
    @media only screen and (max-width: 768px) {
      display: none;
    }
  }
  &FullBacklayer {
    width: 100%;
    inset-block-start: 0;
    inset-inline-end: 0;
    position: absolute;
    height: 10rem;
    background-color: #fff;
    z-index: 2;
    @media only screen and (max-width: 768px) {
      display: none;
    }
  }
  &FullDarkBacklayer {
    width: 100%;
    inset-block-start: 0;
    inset-inline-end: 0;
    position: absolute;
    height: 10rem;
    background-color: #333;
    z-index: 2;
    @media only screen and (max-width: 768px) {
      display: none;
    }
  }
}
