.formSectionImage {
  position: absolute;
  inset-inline-start: 0;
  inset-block-start: 0;
  z-index: -1;
  &:lang(ar) {
    transform: scaleX(-1);
  }
}
.formSectionWrapper {
  position: relative;
  display: flex;
  gap: 4rem;
  padding-block: 12.5% 10%;
  padding-inline: 10%;
  justify-content: space-between;
  @media screen and (max-width: 1200px) {
    padding-inline: 5%;
  }
  .col {
    flex-basis: 50%;
  }

  .col:first-child {
    display: flex;
    flex-direction: column;
    gap: 4rem;

    h2 {
      font-size: 4.5rem;
      font-weight: 700;
      line-height: 1.5;
      max-width: 500px;
      text-transform: capitalize;
    }

    p {
      font-size: 1.8rem;
      font-style: normal;
      font-weight: 400;
      text-transform: capitalize;
      max-width: 450px;
      line-height: 2;
    }

    a {
      font-size: 1.7rem;
      font-weight: 500;
      line-height: 3.7rem;
      text-decoration-line: underline;
      color: #912934;
      align-self: flex-start;
      padding: 2rem;
    }
  }

  @media (max-width: 768px) {
    flex-wrap: wrap;

    .col {
      flex-basis: 100%;

      &:last-child {
        padding: 2rem;
      }
    }
  }
}
