.notFound {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-image: linear-gradient(to right, #fafafa, #eee);
  &Image {
    max-width: 100%;
    object-fit: contain;
    width: 100%;
    margin-block-start: -20%;
  }
  &Content {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: center;
    position: absolute;
    inset-block-end: 15rem;
    &Title {
      text-transform: capitalize;
      font-size: 3rem;
      font-weight: 900;
    }
    &Body {
      text-transform: capitalize;
      font-size: 1.5rem;
      font-weight: 300;
      width: 55%;
      text-align: center;
    }
  }
  &Button {
    all: unset;
    font-size: 1.8rem;
    font-weight: 500;
    color: #fff;
    background-color: #fa3626;
    padding: 1.5rem 3rem;
    border-radius: 3rem;
    text-transform: uppercase;
    cursor: pointer;
  }
}
