:root {
  --max-width: 1100px;
  --border-radius: 12px;
  --font-mono: ui-monospace, Menlo, Monaco, "Cascadia Mono", "Segoe UI Mono",
    "Roboto Mono", "Oxygen Mono", "Ubuntu Monospace", "Source Code Pro",
    "Fira Mono", "Droid Sans Mono", "Courier New", monospace;
  --montserrat: "Montserrat", sans-serif;
  --prata: "Prata";
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;

  --primary-glow: conic-gradient(
    from 180deg at 50% 50%,
    #16abff33 0deg,
    #0885ff33 55deg,
    #54d6ff33 120deg,
    #0071ff33 160deg,
    transparent 360deg
  );
  --secondary-glow: radial-gradient(
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );

  --main-red: #912934;

  --tile-start-rgb: 239, 245, 249;
  --tile-end-rgb: 228, 232, 233;
  --tile-border: conic-gradient(
    #00000080,
    #00000040,
    #00000030,
    #00000020,
    #00000010,
    #00000010,
    #00000080
  );

  --callout-rgb: 238, 240, 241;
  --callout-border-rgb: 172, 175, 176;
  --card-rgb: 180, 185, 188;
  --card-border-rgb: 131, 134, 135;
  font-size: 10px;
  box-sizing: border-box;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
}
html {
  scroll-behavior: smooth;
  font-family: 'Montserrat', sans-serif;
}
html,
body {
  max-width: 100vw;
  overflow-x: hidden;
  font-family: 'Montserrat', sans-serif;
}

.ar {
  direction: rtl;
}

body {
  color: rgb(var(--foreground-rgb));
  /* background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb)); */
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

/* laptops and large screens */
@media screen and (max-width: 1200px) {
  :root {
    font-size: 9px;
  }
}
/* tablets */
@media only screen and (max-width: 992px) {
  :root {
    font-size: 8px;
  }
}
/* mobile phones */
@media only screen and (max-width: 768px) {
  :root {
    font-size: 7px;
  }
}
