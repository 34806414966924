.card {
  background-color: #ffffff;
  padding: 3rem 2rem;
  width: 50%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  border: 1px solid #c4c4c4;
  cursor: pointer;
  display: block;
  &Logo {
    width: auto;
    height: 40px;
    object-fit: contain;
    object-position: left;
    margin-block-end: 3.5rem;
    &:lang(ar) {
      object-position: right;
    }
  }
  &Image {
    position: absolute;
    inset: 0 0 0 50%;
    width: 50%;
    height: 100%;
    object-fit: contain;
    object-position: bottom;
    &:lang(ar) {
      inset: 0 50% 0 0;
      transform: scaleX(-1);
    }
  }
  &Header {
    font-size: 2rem;
    font-weight: 600;
    text-transform: capitalize;
  }
  &Body {
    font-size: 1.25rem;
    margin-block-end: 4rem;
  }
}

.dark {
  background-color: transparent;
}
