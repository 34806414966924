.aboutUs {
  padding-block-start: 10rem;
  &Content {
    padding-block: 5%;
    padding-inline: 10%;
    display: flex;
    align-items: center;
    gap: 4rem;
    @media screen and (max-width: 768px) {
      flex-direction: column;
      gap: 2rem;
      padding-inline: 5%;
    }
  }
  &Header {
    width: 60%;
    font-size: 5rem;
    font-weight: 700;
    @media screen and (max-width: 1200px) {
      width: 50%;
      font-size: 3.25rem;
    }
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
  &Body {
    width: 40%;
    font-size: 1.5rem;
    font-size: 400;
    @media screen and (max-width: 1200px) {
      width: 50%;
    }
    @media screen and (max-width: 768px) {
      width: 100%;
      font-size: 1.8rem;
    }
  }
}
