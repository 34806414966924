.criteria {
  padding-block: 7.5%;
  padding-inline: 10%;
  @media screen and (max-width: 768px) {
    padding-inline: 5%;
  }
}
.mission,
.vision,
.philosophy {
  display: flex;
  gap: 5rem;
  @media screen and (max-width: 768px) {
    gap: 2rem;
  }
  &List {
    position: relative;
    &::after {
      content: "";
      display: inline-block;
      position: absolute;
      width: 1px;
      inset-inline: 50%;
      inset-block: 0;
      background-color: var(--main-red);
      z-index: -1;
    }
  }
  &Icon {
    width: 7.5rem;
    height: 7.5rem;
    padding: 2rem;
    border: 1px solid var(--main-red);
    border-radius: 100%;
    object-fit: contain;
    background-color: #fff;
  }
  &Content {
    display: flex;
    flex-direction: column;
    padding-block: 2rem 5rem;
    gap: 2rem;
  }
  &Title {
    font-size: 3.25rem;
    font-weight: 700;
    text-transform: capitalize;
  }
  &Body {
    font-size: 1.5rem;
    font-weight: 300;
    @media screen and (max-width: 768px) {
      font-size: 1.8rem;
    }
  }
}

.philosophy {
  &List {
    &::after {
      display: none;
    }
  }
}