.info {
  padding-inline: 10%;
  padding-block: 5% 10%;
  display: flex;
  background-image: linear-gradient(#fff 12%, #91293433);
  @media screen and (max-width: 1200px) {
    padding-inline: 5%;
  }
  &NumbersContainer {
    padding-inline: 3rem 0;
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    flex-grow: 1;
    @media screen and (max-width: 851px) {
      flex-wrap: wrap;
    }
  }
}
