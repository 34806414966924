.carousel {
  &Slide {
    position: relative;
    &Link {
      width: 100%;
      display: flex !important;
      justify-content: center;
      align-items: center;
    }
    &Image {
      width: 100%;
      height: 100%;
      max-width: 200px;
      max-height: 100px;
      object-fit: contain;
    }
  }
}
